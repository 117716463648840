import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UserViewModel } from "./user.model";
import { UserService } from "./user.service";
import { AppCookieService } from "../../core/cookies/app-cookie.service";
import { UserModel } from "../../shared/models/user.model";
import { ResultObject } from "../../shared/models/result-object.model";
import { SITE_IDKEY, USER_DETAIL } from "../../core/constants/variables";
import { AppLocationProvider } from "../../shared/providers/app-location.provider";

@Injectable()
export class UserProvider {

  private handler = new Subject<UserModel>();
  private user?: UserModel;
  constructor(
    private userService: UserService,
    protected readonly appCookie: AppCookieService,
    public _appLocationProvider: AppLocationProvider,
  ) {

  }
  broadcast(user: UserModel) {
    this.user = user;
    this.handler.next(user);
  }
  List() {
    return new Promise((result, exception) => {
      this.userService.List().subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  // GetProfileInformation() {
  //   return new Promise((result, exception) => {
  //     this.userService.GetProfileInformation().subscribe(
  //       (response: any) => {
  //         console.log(response)
  //         result(response);
  //       },
  //       (error: any) => { exception(error); });
  //   });
  // }
  ValidateUser(department) {
    return new Promise((result, exception) => {
      this.userService.validate(department).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  Create(user) {
    return new Promise((result, exception) => {
      this.userService.Create(user).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  Update(user) {
    return new Promise((result, exception) => {
      this.userService.Update(user).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  sendTelegramNotification(notification) {
    return new Promise((result, exception) => {
      this.userService.sendTelegramNotification(notification).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }


  Delete(user) {
    return new Promise((result, exception) => {
      this.userService.Delete(user).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  // GetProfile() {

  //   return new Promise((result, exception) => {
  //     this.userService.GetProfile().subscribe(
  //       (response: any) => {
  //         console.log(response)
  //         result(response);
  //       },
  //       (error: any) => { exception(error); });
  //   });
  // }

  GetProfileInformation() {
          return new Promise<ResultObject<UserModel>>((result, exception) => {
              this.userService.GetProfileInformation().subscribe(
                   (response: any) => {
                    let user: any = {
                        AccessType: 'No Access',
                        SiteId: 'no-access',
                      };
                      if (response.Success && response.Data) {
                        user = response.Data;
                      }
                      this.appCookie.set(SITE_IDKEY, String(user?.SiteId));
                      if (user?.AccessType != 'Administrator') {
                        this._appLocationProvider.siteIdChanged.next(String(user?.SiteId));
                      }
                      this.appCookie.set(USER_DETAIL, JSON.stringify(user));
                      this.broadcast(response.Data);
                      result(response);
                   },
                   (error: any) => {
                     exception(error);
                   }
              );
          });
      }

}
