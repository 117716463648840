import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { ThingViewModel } from "../../modules/thing/thing.model";



@Injectable({ providedIn : "root"})
export class ThingService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    )
    {

    }

    List(filter:any={}): any {
        return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getAll", {params:filter});
    }

    FilterThings(model: ThingViewModel): any {
      model.StockStatusId= '2';
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getAll", model);
    }
    FindAllAssets(model: ThingViewModel): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/findThings", model);
    }
    
    Create(model: ThingViewModel): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/create", model);
    }

    GetById(thingId): any {
        return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/thing/get/${thingId}`);
    }

    GetDetails(thingId): any {
        return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/thing/get-details/${thingId}`);
    }
    Count(model: ThingViewModel): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/count", model);
    }
    CountLastMovementTimeStamp(model: ThingViewModel): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/countLastMovementTimeStamp", model);
    }
    BatteryLevelStatistics(model: ThingViewModel): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/batteryLevelStatistics", model);
    }
    CountAssetsPerZone(query:any): any {
        query.StockStatusId= '2';
        return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/countThingsPerZone",{ params:query});
    }
}
