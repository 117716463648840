export const buildingsRequiredRoles = []
export const floorsRequiredRoles = []
export const departmentsRequiredRoles = []
export const locationRequiredRoles = []
export const zonesRequiredRoles = []
export const deviceRequiredRoles = []
export const nodesRequiredRoles = []
export const tagsRequiredRoles = []
export const assetsRequiredRoles = []
export const usersRequiredRoles = []
export const dashboardsRequiredRoles = []
export const activityRequiredRoles = []
export const siteRequiredRoles = []
