// NB! Note - RWS
// Configuration for the DEV Server - DO NOT CHANGE!!
// To build run: NODE_ENV=dev ng build --configuration=dev
// To serve run: NODE_ENV=dev ng serve --configuration=dev

import { KeycloakConfig } from 'keycloak-js';
//import keycloakConfig from './keycloak.config';

export const environment = {
  production: false,
  Name: 'development',
  Version: '1.0.2',
  //GoogleApiKey: 'AIzaSyCNkrNw6LSXNcBMJeoev1cRUAOSRN7Q82o', EXPIRED?
  GoogleApiKey: 'AIzaSyAi2s4UG5fzRx2m_0v5lLDDgeC01TyCnuM',
  PollingInterval: 60000, // milliseconds
  FilesUploadApi : {
    Url : "https://files.sky.sethala.com",
    Auth : 'Basic N2RCVjNyMWg0NUJNR0FTY0JXbGZ3bmFWRllIZ0dmOkNPWUo4NVBGcEVhOGJFSFdIbFIwM2hRNnZpUElnVGNyT2xYM1hqcGxpUDNuYmhGV2dP'
  },
  SethalaIndoorApi: {
    Url: 'https://indoor-api-dev.sethala.app',
    PlatformId: '629c712e-9bcb-4e0b-a50a-46af4eac6c8a',
    AuthenticationKey: 'IXJ3paodOc2eW2cjv0cf617xOicij7Fz',
  },
  SethalaKhiyaApi: {
    ClientId: 'angular-local',
    Url: 'https://khiya.sethala.app',
    GrantType: 'password',
    Realm: 'sethala-dev',
  },
  SethalaCore: {
    Url: 'https://api-nkosi-prod-nsa.sethala.app/v2',
  },
  mqtt: {
    server: 'sky1.sethala.com',
    username: 'sethala-dev',
    password: 'Q98Vb6Ctq2J328-22',
    protocol: 'wss',
    port: 8083,
  },
  keycloak: {
    url: 'https://khiya.sethala.app',
    realm: 'sethala-dev',
    clientId: 'angular-local', 
  },
  appMandetoryRole: ['default-roles-sethala'],
};