import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { FloorViewModel } from "../../modules/floor/floor.model";



@Injectable({ providedIn : "root"})
export class FloorService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    ) {

  }


  List(filter : any ={}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/floor/getAll", {params:filter});
  }
  FilterFloors(model: FloorViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/floor/filterFloors", model);
  }
  Create(model: FloorViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/floor/create", model);
  }

  GetById(floorId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/floor/get/${floorId}`);
  }

  GetDetails(floorId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/floor/get-details/${floorId}`);
  }

  Update(model: FloorViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/floor/update", model);
  }

  Delete(model: FloorViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/floor/delete", model);
  }

  getFloorZone(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/getAll");
  }


}

