import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation,OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationExtras,  Router } from '@angular/router';
import { RouteConfigLoadStart, RouteConfigLoadEnd } from "@angular/router";
import {AppLocationModel, AppLocationProvider } from "@Shared/providers/app-location.provider";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { AuthService } from "../../core/auth/service/auth.service";
import { MediaMatcher } from "@angular/cdk/layout";
import {SelectionModel} from '@angular/cdk/collections';
import { NavItem } from './nav-item';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../components/confirm-dialog/confirm-dialog.component";
import { AppStatusService } from "../../host/app-status.service";
import { UserProvider } from "../../modules/user/user.provider";
import { UserProfileProvider } from "./user.provider";
import { SiteProvider } from "../../components/site/site.provider";
import _ from 'lodash';
import { AppCookieService } from "../../core/cookies/app-cookie.service";
import { SITE_IDKEY } from "../../core/constants/variables";
@Component({
	selector: 'public-shell',
	templateUrl: './secure-shell.page.html',
	styleUrls: ['./secure-shell.page.scss'],
  providers:[SiteProvider]
})
export class SecureShellPage implements OnInit, AfterContentChecked {
	private loadingRouteConfig: boolean;
	public BreadcrumbLocation!: string;
	public UserFullName;
	public IsTokenValid = true;
	public isAdminUser = false;
  public isSuperAdminUser = false;

  ngAfterContentChecked(): void {
    //this.changeDetector.detectChanges();
  }

	public async ngOnInit(): Promise<void> {
		this.isAdminUser = this.authService.isAdminUser();
    this.isSuperAdminUser = this.authService.isSuperAdminUser();
    console.log(this.isAdminUser)
    console.log(this.isSuperAdminUser)
		this.changeDetector.detectChanges();
		this.userProvider.GetProfileInformation().then((result:any)=>{
			if(result.Success){
				this.authService.setPtofile(result.Data)
				this.isAdminUser = this.authService.isAdminUser();
        this.isSuperAdminUser = this.authService.isSuperAdminUser();
				this.changeDetector.detectChanges();
			}
		});
    this.fetchSite();
	}
  isSiteLoading: boolean = false;
  sites: any[] = [];
  selectedSites: any[] = [];
  selectedSiteId: string = '';

  fetchSite(){
      this.siteProvider.List().then((result:any) =>{
        this.sites = result.Data;
        this.selectedSites = result.Data;
      });
  }

  selectedSiteChanged(e: any) {
    console.log(e)
    this.appCookieService.set(SITE_IDKEY, e);
    this.selectedSiteId = e;
    this._appLocationProvider.siteIdChanged.next(e);
  }

  public onSitesKeyUp($e: any) {
    if ($e?.target?.value) {
      const filter = $e.target.value.toLowerCase();
      const data = _.filter(this.sites, (op) => {
        return (
          op.SiteName?.toLowerCase()?.includes(filter) ||
          `${op.SiteName}`?.toLowerCase()?.includes(filter)
        );
      });
      this.selectedSites = [...data];
    } else {
      this.selectedSites = [...this.sites];
    }
  }

  public NavigateToRealtime(){
		this.router.navigateByUrl('/web/(secure:realtime/home)');
	}
	public NavigateToDashboard(){
		this.router.navigateByUrl('/web/(secure:dashboard/home)');
	}
	public ViewNotifications(){
		this.router.navigateByUrl('/web/(secure:notifications/home)');
	}
  public NavigateToRealtime2(){
		this.router.navigateByUrl('/web/(secure:realtime/location)');
	}

  public NavigateToActivity(){
		this.router.navigateByUrl('/web/(secure:activity/home)');
	}

  public NavigateToOverview(){
		this.router.navigateByUrl('/web/(secure:overview/home)');
	}


  public NavigateToBuilding(){
		this.router.navigateByUrl('/web/(secure:building/home)');
	}


  public NavigateToFloor(){
		this.router.navigateByUrl('/web/(secure:floor/home)');
	}


  public NavigateToDepartment(){
		this.router.navigateByUrl('/web/(secure:department/home)');
	}


  public NavigateToZone(){
		this.router.navigateByUrl('/web/(secure:zone/home)');
	}

  public NavigateToLocation(){
		this.router.navigateByUrl('/web/(secure:location/home)');
	}

  public NavigateToDevice(){
		this.router.navigateByUrl('/web/(secure:device/home)');
	}

  public NavigateToThing(){
		this.router.navigateByUrl('/web/(secure:thing/home)');
	}

  public NavigateToAsset(){
		this.router.navigateByUrl('/web/(secure:asset/home)');
	}

  /*public NavigateToContact(){
		this.router.navigateByUrl('/web/(secure:contact/home)');
	}*/

  public NavigateToSite(){
		this.router.navigateByUrl('/web/(secure:site/home)');
	}

  public NavigateToUser(){
		this.router.navigateByUrl('/web/(secure:user/home)');
	}
public ViewProfile(){
	this.router.navigateByUrl('/web/(secure:user/profile)');
}
	public Logout(){
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
			  tittle : "Confirm Logout!",
			  confrimButtonText:"Confirm",
			  cancelButtonText:"No Thanks",
			  message: "Do you want to logout from the system?"
			},
		  });
		  dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.keyCloakAuthService.logout();
			}
		  });

		//
		//this.authService.logout();
		//this.router.navigateByUrl('/web/(public:home)');
	}
	mobileQuery: MediaQueryList;

	private _mobileQueryListener: () => void;

	constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,private router: Router,
		public _appLocationProvider: AppLocationProvider,
		private changeDetector: ChangeDetectorRef,
		private authService:AuthenticationService,
		private keyCloakAuthService : AuthService,
		private dialog: MatDialog,
		public appStatus: AppStatusService,
		private userProvider : UserProfileProvider,
    public siteProvider: SiteProvider,
    private readonly appCookieService: AppCookieService,
	) {
    this._appLocationProvider.subscribe((item) => {
      this.BreadcrumbLocation = item.LocationText;
    })
    this.loadingRouteConfig = false;
	  this.mobileQuery = media.matchMedia('(max-width: 600px)');
	  this._mobileQueryListener = () => changeDetectorRef.detectChanges();
	  this.mobileQuery.addListener(this._mobileQueryListener);
    this.selectedSiteId = appCookieService.get(SITE_IDKEY) || '';
	}

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}
}
