import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { AssetViewModel } from "../../modules/asset/asset.model";



@Injectable({providedIn:"root"})
export class AssetService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    ) {

  }

  ListFilter(query:any): any {
    query.StockStatusId = '2';
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getAll",{ params:query});
  }
  List(query : any = {}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/asset/getAll",{ params:query});
  }
  GetStatus(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/asset/countByStatus");
  }
  CountAssets(query={}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/asset/countDevices",{ params:query});
  }

  CountAssetsHealth(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getThingPercentageCount");
  }

  CountAssetMovement(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getThingMovementCount");
  }


  rawData(query,pageNumber,pageSize): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + `/asset/rawData/${pageNumber}/${pageSize}`,query);
  }

  update(model: any): any {
        return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/asset/updateAsset/"+model.AssetId, model);
    }

  create(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/asset/create", model);
  }
}

